<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" page="div" class="page" ref="observer">
      <header class="page__header">
        <h3 class="page__header_title">{{ page.slug ? `Edit ${page.page_name}` : "Add Page" }}</h3>
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="Save Changes"
          :loading="changesLoading"
          :disabled="invalid"
          @click="savePage"
        ></BaseButton>
      </header>
      <section class="form">
        <!-- <div class="form__row">
          <ValidationProvider
            page="div"
            class="form__row_col"
            :rules="{ required: true, min: 1, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="URL"
              :required="true"
              :validateError="errors[0] || serverError"
              v-model="page.slug"
              :element="page.slug"
              @input="page.slug = $event"
            >
            <template #text>
                <span class="form-control__simple-text">
                  Example: /url
                </span>
              </template>
        </BaseInput>
          </ValidationProvider>
        </div> -->
        <h3 class="form__header">Required Information</h3>
        <div class="form__row">
          <ValidationProvider
            page="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Title"
              :required="true"
              :validateError="errors[0] || serverError"
              v-model="page.title"
              :element="page.title"
              @input="page.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            page="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Subtitle"
              :required="true"
              :validateError="errors[0] || serverError"
              v-model="page.subtext"
              :element="page.subtext"
              @input="page.subtext = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: { PopUp, ValidationObserver, ValidationProvider, BaseInput, BaseButton },
  data() {
    return {
      page: {
        title: "",
        subtext: "",
        slug: "/",
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.page = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("pages", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    page: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.title != "" && oldVal.subtext != "") {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("pages", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.savePage() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    savePage() {
      if (this.page.slug) {
        this.$emit("updatePage", { data: this.page });
      } else {
        this.$emit("createPage", { data: this.page });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
    .button {
      margin-left: 10px;
      @media only screen and (max-width: $xs) {
        margin: 20px 10px 0 0;
      }
    }
    &_title {
      @include type($fs: 3.4rem, $lh: 1.23, $fw: 600);
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 26px;
      }
    }
  }
}
</style>

