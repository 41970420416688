<template>
  <div class="page">
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <!-- <div class="page__header_right">
        <IconButton class="button--auto button--uppercase" text="add page" @click="modal = true">
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div> -->
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <Page
      v-if="modal"
      :modal="modal"
      :item="page"
      @hideModal="modal = false"
      @createPage="createPage"
      @updatePage="updatePage"
      @removePage="confirmRemovePage"
    ></Page>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import Table from "@/components/Table.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Page from "./Page.vue";
export default {
  components: {
    HorisontalTabsList,
    Table,
    IconButton,
    IconPlusBold,
    Page
  },
  data() {
    return {
      tabs: [
        {
          title: "Blogs",
          path: "/admin/content/blogs",
          key: "blog",
        },
        {
          title: "Publications",
          path: "/admin/content/publications",
          key: "editorial",
        },
        {
          title: "Tag Manager",
          path: "/admin/content/tag-manager",
          key: "tags",
        },
        {
          title: "Pages",
          path: "/admin/content/pages",
          key: "pages",
        },
      ],
      modal: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
      },
    };
  },
  async created() {
    await this.apiGetPages(this.tableData);
    this.initialPage();
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialPage();
        this.setError(null);
      }
    },
  },
  computed: {
    ...mapGetters("pages", {
      items: "getPages",
      headerItems: "getPagesHeaders",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      page: "getPage",
    }),
  },
  methods: {
    ...mapActions("pages", [
      "apiGetPages",
      "apiGetPage",
      "apiPostPage",
      "apiPutPage",
      "apiDeletePage",
    ]),
    ...mapMutations("pages", ["initialPage", "setError"]),
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetPages(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetPages(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetPage(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemovePage(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
      }
    },
    async createPage({ data }) {
      let result = await this.apiPostPage(data);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetPages(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Page successfully created",
          confirmButtonText: "Ok",
        });
      }
    },
    async updatePage({ data }) {
      let result = await this.apiPutPage(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetPages(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Page successfully updated",
          confirmButtonText: "Ok",
        });
      }
    },
    confirmRemovePage(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>This page will be removed <br/> everywhere it appears",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removePage(item.id);
        }
      });
    },
    async removePage(id) {
      let result = await this.apiDeletePage(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetPages(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
}
</style>
